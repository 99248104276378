import { graphql } from 'gatsby'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { breakpoints, colors, layout } from '../theme'
import Helmet from 'react-helmet'
import LargeBanner, {
  StyledImg,
  StyledLargeBanner,
} from '../components/LargeBanner'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Dropdown, StyledDropdown } from '@monbanquet/crumble'
import { CheckoutContext } from '../components/context/CheckoutContext'
import { useRedirect } from '../hooks/useRedirect'
import { httpFetch } from '../util/httpFetch'

const demandSources = [
  'Je suis déjà client',
  'Recherche Google ou autre moteur de recherche',
  'Publicité en ligne',
  "Recommandation d'un ami",
  "Recommandation d'un collègue",
  'Réseaux Sociaux',
  "J'ai été contacté par quelqu'un de Monbanquet.fr",
  "J'ai participé à un événement avec des produits Monbanquet.fr",
  'Je vous ai découvert sur un salon',
  'Presse(journal, TV, Radio...)',
]

const ThankYouPage = ({ location: { state }, ...props }) => {
  const hasOrdered = state && state.order
  const hasQuoted = state && state.quote

  useRedirect(!hasOrdered && !hasQuoted, '/')

  const {
    state: { demandId },
  } = useContext(CheckoutContext)
  const [demandSource, setDemandSource] = useState()

  return (
    <StyledThankYouPage>
      <Helmet meta={[{ name: 'robots', content: 'noindex' }]} />
      <Navbar />
      <div className="page-content">
        <LargeBanner {...props.data.banner} pictureClipped>
          <h1>
            {hasOrdered
              ? `Nous avons bien reçu votre demande. Merci\u00A0!`
              : `Merci de votre visite\u00A0!`}
          </h1>
        </LargeBanner>

        <div className="content">
          {hasOrdered && (
            <>
              <h2>Et maintenant&nbsp;?</h2>
              <p>
                Vous allez bientôt recevoir une confirmation par email avec le
                récapitulatif de votre demande.
              </p>
            </>
          )}
          {demandId && (
            <div className="card">
              <span>
                Comment avez-vous entendu parler de Monbanquet.fr&nbsp;?
              </span>
              <Dropdown
                items={demandSources.map(d => ({ value: d, label: d }))}
                value={demandSource}
                onChange={v => {
                  setDemandSource(v)
                  httpFetch(`${process.env.GATSBY_VENUS_URL}/demand-source`, {
                    method: 'POST',
                    body: {
                      demandId,
                      demandSource: v,
                    },
                  })
                }}
              />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </StyledThankYouPage>
  )
}

const StyledThankYouPage = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .page-content {
    margin-top: ${layout.navbarHeight.normal}px;
    flex-grow: 1;
  }

  @media (max-width: ${breakpoints.mobile}px) {
    .page-content {
      margin-top: ${layout.navbarHeight.mobile}px;
    }
  }

  ${StyledLargeBanner} {
    height: 400px;

    .text-wrapper {
      width: 60%;
    }

    ${StyledImg} {
      width: 40%;
      margin-left: 60%;

      img {
        object-fit: cover !important;
      }
    }
  }

  ${StyledLargeBanner} .wrapper,
  .content {
    margin: 0 auto;
    max-width: ${layout.maxWidth}px;
    width: ${layout.width};
  }

  h2 {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  p,
  span {
    font-size: 1.2rem;
    margin-bottom: 25px;
  }

  ${StyledDropdown} {
    height: 36px;

    ul {
      z-index: 10;
      bottom: 40px;
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    max-width: 320px;
    color: ${colors.text.blue};
    border: 1px solid ${colors.text.blue};
    padding: 30px;
    margin: auto;
    margin-top: 40px;
    border-radius: 5px;
}
  }

  @media (max-width: ${breakpoints.tablet}px) {
    ${StyledLargeBanner} > .picture > ${StyledImg} {
      transform: none;
      margin-left: 40%;
      width: 60%;
    }
  }

  @media (max-width: ${breakpoints.mobile}px) {
    ${StyledLargeBanner} {
      height: 200px;
      padding-top: 55px;

      .text-wrapper {
        width: 100%;
      }
    }
  }

  &.no-content + footer {
    display: none;
  }
`

export const query = graphql`
  {
    banner: contentfulCallToAction(fixedId: { eq: "cta-2" }) {
      ...CallToActionFragment
    }
  }
`

export default ThankYouPage
export { StyledThankYouPage }
